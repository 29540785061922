// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-qr-tsx": () => import("./../../../src/pages/qr.tsx" /* webpackChunkName: "component---src-pages-qr-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-category-page-template-tsx": () => import("./../../../src/templates/CategoryPageTemplate.tsx" /* webpackChunkName: "component---src-templates-category-page-template-tsx" */),
  "component---src-templates-legal-document-page-template-tsx": () => import("./../../../src/templates/LegalDocumentPageTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-document-page-template-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../../../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */),
  "component---src-templates-redirect-template-tsx": () => import("./../../../src/templates/RedirectTemplate.tsx" /* webpackChunkName: "component---src-templates-redirect-template-tsx" */),
  "component---src-templates-suppliers-page-template-tsx": () => import("./../../../src/templates/SuppliersPageTemplate.tsx" /* webpackChunkName: "component---src-templates-suppliers-page-template-tsx" */)
}

